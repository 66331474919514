//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'

export default {
  name: 'PlatformExample',
  components: {
    ScrollWrapperV2,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      videoWidth: '1160px',
      videoHeight: '640px',
      titleIndex: 0,
      isShowTitle: true,
      titleInterval: null,
      icons: [
        require('@/assets/img/v2/ItGoals/1.svg'),
        require('@/assets/img/v2/ItGoals/2.svg'),
        require('@/assets/img/v2/ItGoals/3.svg'),
        require('@/assets/img/v2/ItGoals/4.svg'),
      ],
    }
  },
  computed: {
    dynamicStyles() {
      return {
        width: this.videoWidth,
        height: this.videoHeight,
        margin: '0 auto',
        maxWidth: '1160px',
        maxHeight: '640px',
      }
    },
    blockTitle() {
      return `${this.detailData.data.title} `
    },
  },
  mounted() {
    this.updateVideoSize()
    window.addEventListener('resize', this.updateVideoSize)
    this.titleInterval = setInterval(() => this.changeTitle(), 5000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateVideoSize)
    clearInterval(this.titleInterval)
  },
  methods: {
    updateVideoSize() {
      if (window.innerWidth <= 768) {
        this.videoWidth = '300px'
        this.videoHeight = '301px'
      } else {
        this.videoWidth = '1160px'
        this.videoHeight = '640px'
      }
    },
    changeTitle() {
      this.isShowTitle = false
      setTimeout(() => {
        this.titleIndex =
          this.titleIndex + 1 < this.detailData.data.textOptions.length
            ? this.titleIndex + 1
            : 0
        this.isShowTitle = true
      }, 300)
    },
  },
}
