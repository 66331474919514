//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
export default {
  name: 'TestimonialsCustomers',
  components: {
    ScrollWrapperV2,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      rating: 1,
      arrowButton: require('@/assets/img/v2/arrowButton.svg'),
      stars: require('@/assets/img/v2/stars.svg'),
    }
  },
  computed: {
    isDisplayBlock() {
      return !this.detailData.data.isDisplay
    },
  },
  methods: {
    // для скрола через кнопки
    prevCard() {
      const wrapper = this.$refs.scrollWrapper.$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, -scrollAmount)
    },
    nextCard() {
      const wrapper = this.$refs.scrollWrapper.$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, scrollAmount)
    },
    smoothScroll(element, amount) {
      const start = element.scrollLeft
      const change = amount
      const duration = 300
      let currentTime = 0
      const increment = 20

      const animateScroll = () => {
        currentTime += increment
        const val = this.easeInOutQuad(currentTime, start, change, duration)
        element.scrollLeft = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }

      animateScroll()
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    },
  },
}
