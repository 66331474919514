//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalCloseButton from '@/components/v2/span/ModalCloseButton.vue'

export default {
  name: 'ProgramDetailsPopup',
  components: { ModalCloseButton },
  props: {
    isVis: {
      type: Boolean,
      default: false,
    },
    indexEl: {
      type: Number,
      default: -1,
    },
    dataEl: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  methods: {
    closePopUp() {
      this.$emit('close')
    },
  },
}
