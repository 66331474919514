//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardCause',
  props: {
    title: {
      type: [String, Number],
      required: true,
      default: '',
      validator(value) {
        return typeof value === 'string' || typeof value === 'number'
      },
    },
    description: {
      type: String,
      required: true,
    },
    svgVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      star: require('@/assets/img/v2/star.svg'),
    }
  },
}
