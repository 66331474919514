//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FAQ',
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      cross: require('@/assets/img/v2/cross.svg'),
      activeIndex: null,
    }
  },
  methods: {
    toggleActive(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
  },
}
