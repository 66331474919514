//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
import CursorChanger from '@/components/v2/span/CursorChanger.vue'
export default {
  name: 'WillDo',
  components: {
    ScrollWrapperV2,
    CursorChanger,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: Number,
        data: {},
      }),
    },
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  methods: {},
}
