//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CtaButton from '@/components/v2/button/CtaButton.vue'
import InfoBox from '@/components/v2/button/InfoBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FirstSteps',
  components: {
    CtaButton,
    InfoBox,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      pathImgDetail: '',
      firstSteps2: require('@/assets/img/v2/firstSteps2.png'),

      isShowText: true,
      currentTextIndex: 1,
      intervalId: null,
      icons: [
        require('@/assets/img/v2/firstSteps/1.png'),
        require('@/assets/img/v2/firstSteps/2.png'),
        require('@/assets/img/v2/firstSteps/3.png'),
      ],
    }
  },
  computed: {
    ...mapGetters({
      mainSettings: 'landings/mainSettings',
    }),
    borderAngleClass() {
      return {
        'border-angle': true,
        'border-angle--dynamic': true,
      }
    },
    borderAngleStyle() {
      const imagePath = require(`@/assets/img/v2/detail/${this.detailData.routePath}/spanBorder.svg`)
      return {
        '--image-span-angle-url': `url(${imagePath})`,
      }
    },
    realData() {
      const dataForDetail = {
        ...this.detailData.data.dataC2,
        p: this.mainSettings?.v2IndexFirstStepDescriptionBeginning?.[0] || '',
        descriptionStart:
          this.mainSettings?.v2IndexFirstStepDescriptionBeginning?.[0] || '',
        goal1prefix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPrefix1?.[0] || '',
        goal1postfix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPostfix1?.[0] || '',
        goal2prefix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPrefix2?.[0] || '',
        goal2postfix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPostfix2?.[0] || '',
        goal3prefix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPrefix3?.[0] || '',
        goal3postfix:
          this.mainSettings?.v2IndexFirstStepDescriptionIconPostfix3?.[0] || '',
        descriptionEnd:
          this.mainSettings?.v2IndexFirstStepDescriptionEnding?.[0] || '',
        listItems: [
          // this.mainSettings?.v2IndexFirstStepText1?.[0] || '',
          // this.mainSettings?.v2IndexFirstStepText2?.[0] || '',
          // this.mainSettings?.v2IndexFirstStepText3?.[0] || '',
        ],
      }
      const data = this.detailData.isDetail
        ? dataForDetail
        : this.detailData.data.dataIndex

      data.descriptionStart = data.descriptionStart?.trim() || ''
      data.goal1prefix = data.goal1prefix?.trim() || ''
      data.goal2prefix = data.goal2prefix?.trim() || ''
      data.goal3prefix = data.goal3prefix?.trim() || ''

      return data
    },
  },
  watch: {
    detailData() {
      this.updatePathImgDetail()
    },
  },
  // после того, как компонент был установлен на DOM
  mounted() {
    this.updatePathImgDetail()
    const wrapper = this?.$refs?.infoWrapper
    let isDown = false
    let startX
    let scrollLeft

    wrapper?.addEventListener('mousedown', (e) => {
      isDown = true
      wrapper.classList.add('active')
      startX = e.pageX - wrapper.offsetLeft
      scrollLeft = wrapper.scrollLeft
    })

    wrapper?.addEventListener('mouseleave', () => {
      isDown = false
      wrapper.classList.remove('active')
    })

    wrapper?.addEventListener('mouseup', () => {
      isDown = false
      wrapper.classList.remove('active')
    })

    wrapper?.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - wrapper.offsetLeft
      const walk = (x - startX) * 3 // Скорость прокрутки
      wrapper.scrollLeft = scrollLeft - walk
    })
    this.setAutoChange()
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    updatePathImgDetail() {
      const { routePath, viewportWidth } = this.detailData

      if (!routePath || routePath === 'index') {
        this.pathImgDetail = this.firstSteps2
      } else if (viewportWidth <= 768) {
        this.pathImgDetail = require(`@/assets/img/v2/detail/${routePath}/logoPagePhone.png`)
      } else {
        this.pathImgDetail = require(`@/assets/img/v2/detail/${routePath}/logoPage.png`)
      }
    },
    changeTextWrapper(callback) {
      this.isShowText = false
      setTimeout(() => {
        callback()
        this.isShowText = true
      }, 300)
    },
    nextText() {
      this.changeTextWrapper(() => {
        this.currentTextIndex =
          this.currentTextIndex + 1 > 3 ? 1 : this.currentTextIndex + 1
      })
    },
    setAutoChange() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(this.nextText, 5000)
    },
  },
}
