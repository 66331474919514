//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardWhatWeOffer',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    trainingDates: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  methods: {},
}
