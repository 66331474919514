//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaveDataAdapter from '~/components/v2/adapters/SaveDataAdapter.vue'
export default {
  name: 'AdapterMaskNumberFooter',
  components: { SaveDataAdapter },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    pText: {
      type: String,
      default: '',
    },
    placeholderNameText: {
      type: String,
      default: '',
    },
    placeholderPhoneText: {
      type: String,
      default: '',
    },
    placeholderEmailText: {
      type: String,
      default: '',
    },
    newsletterText: {
      type: String,
      default: '',
    },
    privacyPolicyText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    validationTexts: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  methods: {},
}
