//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaveDataAdapter from '@/components/v2/adapters/SaveDataAdapter.vue'
export default {
  name: 'ApplicationForm',
  components: { SaveDataAdapter },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },

  data() {
    return {
      egeChildren: require('@/assets/img/v2/egeChildren.png'),
      backgroundImage: require('@/assets/img/v2/application-form-background.png'),
      backgroundImageMobile: require('@/assets/img/v2/application-form-background-mobile.png'),
      formData: {
        parentName: '',
        phone: '',
        email: '',
        newsletter: true,
        privacyPolicy: true,
      },
    }
  },
  methods: {
    clearInput() {
      this.formData.parentName = ''
      this.formData.phone = ''
      this.formData.email = ''
      this.formData.newsletter = true
      this.formData.privacyPolicy = true
    },
    handleSubmit() {
      this.$store.dispatch('requestSuccess/showPopUp')
      // console.log(this.formData);
      // код для отправки данных формы
      // фома очищяется
      this.clearInput()
    },
  },
}
