//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import CtaButton from '@/components/v2/button/CtaButton.vue'
import CursorChanger from '@/components/v2/span/CursorChanger.vue'
import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
export default {
  name: 'ItGoals',
  components: {
    CtaButton,
    CursorChanger,
    ScrollWrapperV2,
  },

  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      arrowButton: require('@/assets/img/v2/arrowButton.svg'),
      // через scss можно менять фото каждых 1 секунду
      cursor1: require('@/assets/img/v2/ItGoals/cursor1.svg'),
      cursor2: require('@/assets/img/v2/ItGoals/cursor2.svg'),
      cursor3: require('@/assets/img/v2/ItGoals/cursor3.svg'),
      cursor4: require('@/assets/img/v2/ItGoals/cursor4.svg'),
      coursesImg: {
        scratch: 'scratch.png',
        frontend: 'frontend.png',
        game_design: 'game_design.png',
        graphic_design: 'graphic_design.png',
        main: 'main.png',
        python: 'python.png',
        unity: 'unity.png',
        videoblogging: 'videoblogging.png',
        'web-razrabotka': 'web-razrabotka.png',
        computer_literacy: 'computer_literacy.png',
        programming_basics: 'programming_basics.png',
        predprinimatelstvo: 'predprinimatelstvo.png',
        ai: 'ai.png',
      },
      currentCard: 0,
      // основные данные
      startEndPoints: {
        ageCards: {
          isAtStart: true,
          isAtEnd: true,
        },
        studyDirections: {
          isAtStart: true,
          isAtEnd: true,
        },
      },
    }
  },
  computed: {
    ...mapState(['i18n']),
    activeCards() {
      if (!this.detailData?.data?.studyDirectionsFoto) return []
      return this.detailData.data.studyDirectionsFoto.filter(
        (el) => el.is_active
      )
    },
    cardsForShow() {
      if (!this.detailData?.data?.ageCards?.[this.currentCard]?.courses)
        return []
      return this.activeCards.filter((el) =>
        this.detailData?.data?.ageCards?.[this.currentCard]?.courses.includes(
          el?.code
        )
      )
    },
    ageCardsForShow() {
      return this.detailData?.data?.ageCards.map((el) => ({
        ...el,
        isHidden: !this.activeCards?.filter((elem) =>
          el?.courses.includes(elem?.code)
        )?.length,
      }))
    },
    featuredCards() {
      if (!this.detailData?.data?.studyDirectionsFoto) return []
      return this.detailData.data.studyDirectionsFoto.filter(
        (el) =>
          el.is_active && (el.code === 'ege-informatika' || el.code === 'math')
      )
    },
  },
  mounted() {
    this.setCard(this.ageCardsForShow.map((el) => !el.isHidden).indexOf(true))
  },
  methods: {
    setCard(card) {
      this.currentCard = card
    },
    goToCourse(url) {
      window.location.href = this.localePath(url, this.i18n.locale)
    },
    prevCard(refName) {
      const wrapper = this.$refs[refName].$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, -scrollAmount)
    },
    nextCard(refName) {
      const wrapper = this.$refs[refName].$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, scrollAmount)
    },
    smoothScroll(element, amount) {
      const start = element.scrollLeft
      const change = amount
      const duration = 300
      let currentTime = 0
      const increment = 20

      const animateScroll = () => {
        currentTime += increment
        const val = this.easeInOutQuad(currentTime, start, change, duration)
        element.scrollLeft = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }

      animateScroll()
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    },
    setStartEndPoints(targetName, points) {
      this.startEndPoints[targetName] = points
    },
  },
}
