//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ScrollWrapperV2',
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      touchStartX: 0,
      touchScrollLeft: 0,
      scrollTimeout: null,
    }
  },
  mounted() {
    this.emitPosition()
  },
  methods: {
    startScroll(e) {
      this.isDown = true
      this.$refs.scrollWrapper.classList.add('active')
      this.startX = e.pageX - this.$refs.scrollWrapper.offsetLeft
      this.scrollLeft = this.$refs.scrollWrapper.scrollLeft
    },
    endScroll() {
      this.isDown = false
      this.$refs.scrollWrapper.classList.remove('active')
      this.emitPosition()
    },
    scrollContent(e) {
      if (!this.isDown) return
      e.preventDefault()
      const x = e.pageX - this.$refs.scrollWrapper.offsetLeft
      const walk = (x - this.startX) * 3 // Скорость прокрутки
      this.$refs.scrollWrapper.scrollLeft = this.scrollLeft - walk
    },
    startTouchScroll(e) {
      this.isDown = true
      this.touchStartX =
        e.touches[0].pageX - this.$refs.scrollWrapper.offsetLeft
      this.touchScrollLeft = this.$refs.scrollWrapper.scrollLeft
    },
    endTouchScroll() {
      this.isDown = false
    },
    touchScrollContent(e) {
      // if (!this.isDown) return
      // e.preventDefault()
      // const x = e.touches[0].pageX - this.$refs.scrollWrapper.offsetLeft
      // const walk = (x - this.touchStartX) * 3 // Скорость прокрутки
      // this.$refs.scrollWrapper.scrollLeft = this.touchScrollLeft - walk
    },
    emitPosition() {
      const scrollWrapper = this.$refs.scrollWrapper
      const isAtStart = scrollWrapper.scrollLeft === 0
      const isAtEnd =
        scrollWrapper.scrollLeft >=
        scrollWrapper.scrollWidth - scrollWrapper.clientWidth
      this.$emit('startEndPoints', { isAtStart, isAtEnd })
    },
  },
}
