//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'

export default {
  name: 'LongTermLearning',
  components: {
    ScrollWrapperV2,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      arrowButton: require('@/assets/img/v2/arrowButton.svg'),
      cardAutoSwitchOld: [
        require('@/assets/img/v2/learning/learnGif1.gif'),
        require('@/assets/img/v2/learning/learnGif2.gif'),
        require('@/assets/img/v2/learning/learnGif3.gif'),
        require('@/assets/img/v2/learning/learnGif4.gif'),
      ],
      cardIcons: [
        require('@/assets/img/v2/violet-heart.png'),
        require('@/assets/img/v2/red-fire.png'),
        require('@/assets/img/v2/green-thumb.png'),
        require('@/assets/img/v2/yellow-hello.png'),
      ],
      currentCardIndex: 0,
      autoSwitchIndex: 0,
      previousAutoSwitchIndex: -1,
      isDesktop: false,
      intervalId: null,
      isShowText: true,
    }
  },
  computed: {
    currentCard() {
      return this.detailData.data.teachData[this.currentCardIndex]
    },
    cardAutoSwitch() {
      return this.detailData.data.teachData
        .filter((item) => item.logo[0]?.url !== undefined)
        .map((item) => item.logo[0].url)
    },
    cardAutoSwitchCard() {
      return this.cardAutoSwitch[this.autoSwitchIndex]
    },
  },
  mounted() {
    this.setAutoChange()
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
    this.preloadImages(this.cardAutoSwitch)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    checkWidth() {
      this.isDesktop = window.innerWidth > 768
    },
    nextCard() {
      this.changeTextWrapper(() => {
        this.currentCardIndex =
          (this.currentCardIndex + 1) % this.detailData.data.teachData.length

        this.autoSwitchIndex = this.currentCardIndex
        this.setAutoChange()
      })
    },
    prevCard() {
      this.changeTextWrapper(() => {
        this.currentCardIndex =
          (this.currentCardIndex - 1 + this.detailData.data.teachData.length) %
          this.detailData.data.teachData.length
        this.autoSwitchIndex = this.currentCardIndex
        this.setAutoChange()
      })
    },
    autoSwitchCards() {
      this.previousAutoSwitchIndex = this.autoSwitchIndex
      this.autoSwitchIndex =
        (this.autoSwitchIndex + 1) % this.cardAutoSwitch.length
    }, // cardAutoSwitch отвечает за то по какому списку надо проходить
    touchImageCard() {
      this.autoSwitchCards()
    },
    preloadImages(urls) {
      urls.forEach((url) => {
        const img = new Image()
        img.src = url
      })
    },
    setAutoChange() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(this.nextCard, 5000)
    },
    changeTextWrapper(callback) {
      this.isShowText = false
      setTimeout(() => {
        callback()
        this.isShowText = true
      }, 300)
    },
  },
}
