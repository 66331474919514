// порядок указан в котором будут разрабатываться страницы
export default {
  main: '#FFD832',
  predprinimatelstvo: '#FF9F41',
  'ege-informatika': '#06BAD5',
  'osnovy-programmirovaniya': '#96F597',
  python: '#2CBB45',
  unity: '#FF4D43',
  'web-razrabotka': '#FF7D1B',
  'game-design': '#A55AF3',
  'graphic-design': '#FFD832',
  videoblogging: '#EB63DF',
  scratch: '#18A0FB',
  'computernaya-gramotnost': '#FFD832',
  frontend: '#AD0C98',
  ai: '#265FDA',
  intensive: '#FFD832',
}

export const path = [
  'main',
  'predprinimatelstvo',
  'ege-informatika',
  'osnovy-programmirovaniya',
  'python',
  'unity',
  'web-razrabotka',
  'game-design',
  'graphic-design',
  'videoblogging',
  'scratch',
  'computernaya-gramotnost',
  'frontend',
  'ai',
  'intensive',
]

export const colorBackground = {
  main: { baseColor: '', leftLower: '#FFD832', upperRight: '#FFD832' },
  predprinimatelstvo: {
    baseColor: '',
    leftLower: '#FBA300',
    upperRight: '#FFD058',
  },
  'ege-informatika': {
    baseColor: '',
    leftLower: '#14DAF2',
    upperRight: '#3AFFAD',
  },
  'osnovy-programmirovaniya': {
    baseColor: '',
    leftLower: '#9CFA4A',
    upperRight: '#28E880',
  },
  python: { baseColor: '', leftLower: '#5CEA2F', upperRight: '#B2F03F' },
  unity: { baseColor: '', leftLower: '#E72D64', upperRight: '#FD6744' },
  'web-razrabotka': {
    baseColor: '',
    leftLower: '#E93232',
    upperRight: '#FF9B3E',
  },
  'game-design': { baseColor: '', leftLower: '#944CE5', upperRight: '#DE76FB' },
  'graphic-design': {
    baseColor: '',
    leftLower: '#FFBB33',
    upperRight: '#FFED41',
  },
  videoblogging: { baseColor: '', leftLower: '#F94EF0', upperRight: '#FB74FF' },
  scratch: {
    baseColor: '',
    leftLower: '#28B7F4',
    upperRight: '#4CF0FF',
  },
  'computernaya-gramotnost': {
    baseColor: '',
    leftLower: '#FFC135',
    upperRight: '#FFEC41',
  },
  frontend: { baseColor: '', leftLower: '#E30BC0', upperRight: '#980CEE' },
  ai: { baseColor: '', leftLower: '#265FDA', upperRight: '#50ACFF' },
  intensive: { baseColor: '', leftLower: '#FFD832', upperRight: '#FFD832' },
}
