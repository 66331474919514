//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LogoBase',
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    isFixedNav: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logoSrc: require('@/assets/img/v2/logoAlg.svg'),
      logoHeader: require('@/assets/img/v2/logoHeader.svg'),
      logoHeaderPc: require('@/assets/img/v2/logoHeaderPc.svg'),
      logoNavPopUp: require('@/assets/img/v2/logoNavPopUp.svg'),
      isHeaderMobile: false,
    }
  },

  computed: {
    localizedUrl() {
      const currentLocale = this.$i18n.locale || this.$route.params.lang || 'en'
      if (!!this.href && this.href === '/') {
        return `/${currentLocale}`
      } else if (this.href) {
        return `/${currentLocale}/${this.href}`
      }
      return `/${currentLocale}`
    },
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // Проверяем наличие класса detail в теге main
      const mainElement = document.querySelector('main')
      const isDetail = mainElement && mainElement.classList.contains('detail')
      this.isHeaderMobile =
        (isDetail && window.innerWidth <= 1000) ||
        (!isDetail && window.innerWidth <= 1151)
    },
  },
}
