//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardWhatWeOffer from '@/components/v2/card/CardWhatWeOffer.vue'
import SummerPromoPopup from '@/components/v2/pop-up/SummerPromo.vue'
export default {
  name: 'WhatWeOffer',
  components: {
    CardWhatWeOffer,
    SummerPromoPopup,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      backgroundImageLine: require(`@/assets/img/v2/detail/${this.detailData.routePath}/line.svg`),
      showPopup: false,
    }
  },
  watch: {},
  mounted() {},

  methods: {
    openInfoPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
  },
}
