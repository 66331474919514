//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardCause from '@/components/v2/card/CardCause.vue'
export default {
  name: 'TrustSection',
  components: {
    CardCause,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {}
  },
  computed: {
    builtCards() {
      const result = this.detailData.data.cards
      result[0] = {
        title: this.detailData.data.achievementStudents,
        description:
          this.detailData.data.achievementStudentsDescription +
          ' ' +
          this.detailData.data.achievementCountries +
          ' ' +
          this.detailData.data.achievementCountriesDescription +
          ' ' +
          this.detailData.data.achievementCities +
          ' ' +
          this.detailData.data.achievementCitiesDescription,
      }

      return result
    },
  },
}
