//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardAutoSwitch',
  components: {},
  props: {
    cardAutoSwitch: {
      type: Array,
      required: true,
      default: () => [],
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    wrapperClassContent: {
      type: String,
      default: '',
    },
    noIndicatorForOneItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoSwitchIndex: 0,
      previousAutoSwitchIndex: -1,
    }
  },
  computed: {
    cardAutoSwitchCard() {
      return this.cardAutoSwitch[this.autoSwitchIndex]
    },
  },
  mounted() {
    setInterval(this.autoSwitchCards, 5000)
    this.preloadImages(this.cardAutoSwitch)
  },

  methods: {
    autoSwitchCards() {
      this.previousAutoSwitchIndex = this.autoSwitchIndex
      this.autoSwitchIndex =
        (this.autoSwitchIndex + 1) % this.cardAutoSwitch.length
    }, // cardAutoSwitch отвечает за то по какому списку надо проходить
    touchImageCard() {
      this.autoSwitchCards()
    },
    preloadImages(urls) {
      urls.forEach((url) => {
        const img = new Image()
        img.src = url
      })
    },
  },
}
