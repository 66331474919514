var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"it-goals",attrs:{"id":"courses"}},[_c('div',{staticClass:"it-goals-subject"},[_c('h2',[_vm._v(_vm._s(_vm.detailData.data.header)+" "),_c('CursorChanger')],1)]),_vm._v(" "),_c('div',{staticClass:"scroll-button-container"},[_c('ScrollWrapperV2',{ref:"cardWrapper",attrs:{"wrapper-class":"age-cards"},on:{"startEndPoints":function($event){return _vm.setStartEndPoints('ageCards', $event)}}},_vm._l((_vm.ageCardsForShow),function(card,index){return _c('div',{key:card.title,class:{
          'age-card': true,
          first: index === _vm.currentCard,
          fourth: index === 3,
          hidden: card.isHidden,
        },on:{"click":function($event){return _vm.setCard(index)}}},[_c('span',[_vm._v(_vm._s(card.title))]),_vm._v(" "),_c('p',{staticClass:"limited-lines"},[_vm._v("\n          "+_vm._s(card.text)+"\n          "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require(("@/assets/img/v2/ItGoals/" + (card.span))),"alt":"icon"}})])])}),0),_vm._v(" "),_c('button',{staticClass:"arrow-button left age-cards_arrow-button",class:{ 'is-hide': _vm.startEndPoints.ageCards.isAtStart },style:({ backgroundImage: ("url(" + _vm.arrowButton + ")") }),on:{"click":function($event){return _vm.prevCard('cardWrapper')}}}),_vm._v(" "),_c('button',{staticClass:"arrow-button right age-cards_arrow-button",class:{ 'is-hide': _vm.startEndPoints.ageCards.isAtEnd },style:({ backgroundImage: ("url(" + _vm.arrowButton + ")") }),on:{"click":function($event){return _vm.nextCard('cardWrapper')}}})],1),_vm._v(" "),_c('div',{staticClass:"scroll-button-container"},[_c('ScrollWrapperV2',{ref:"scrollWrapper",attrs:{"wrapper-class":"study-directions"},on:{"startEndPoints":function($event){return _vm.setStartEndPoints('studyDirections', $event)}}},_vm._l((_vm.cardsForShow),function(direction){return _c('div',{key:direction.code,staticClass:"study-direction",style:({
          backgroundImage: ("url(" + (require(("@/assets/img/v2/ItGoals/coursesNew/" + (_vm.coursesImg[direction.code] || 'main.png')))) + ")"),
        }),on:{"click":function($event){return _vm.goToCourse(direction.url)}}},[_c('div',{staticClass:"direction-text"},[_c('p',{staticClass:"direction",domProps:{"innerHTML":_vm._s(direction.childrens_age)}}),_vm._v(" "),_c('h4',{domProps:{"innerHTML":_vm._s(direction.title)}}),_vm._v(" "),_c('p',{staticClass:"direction description"},[_vm._v(_vm._s(direction.description))]),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.goToCourse(direction.url)}}},[_vm._v("\n            "+_vm._s(_vm.detailData.data.textButton)+" >\n          ")])])])}),0),_vm._v(" "),_c('button',{staticClass:"arrow-button left",class:{ 'is-hide': _vm.startEndPoints.studyDirections.isAtStart },style:({ backgroundImage: ("url(" + _vm.arrowButton + ")") }),on:{"click":function($event){return _vm.prevCard('scrollWrapper')}}}),_vm._v(" "),_c('button',{staticClass:"arrow-button right",class:{ 'is-hide': _vm.startEndPoints.studyDirections.isAtEnd },style:({ backgroundImage: ("url(" + _vm.arrowButton + ")") }),on:{"click":function($event){return _vm.nextCard('scrollWrapper')}}})],1),_vm._v(" "),_c('CtaButton',{attrs:{"text":_vm.detailData.data.textButton,"text-path":"#application-form","max-button":true}}),_vm._v(" "),_c('div',{staticClass:"advantages_container"},_vm._l((_vm.detailData.data.benefits),function(benefit,index){return _c('ul',{key:index,staticClass:"advantages"},[_c('li',{staticClass:"advantage"}),_vm._v(" "),_c('p',[_vm._v(_vm._s(benefit))])])}),0),_vm._v(" "),(_vm.featuredCards.length)?_c('div',{staticClass:"as-well-as"},[_c('h3',[_vm._v(_vm._s(_vm.detailData.data.asWellAs.commonTitle))]),_vm._v(" "),_c('div',{staticClass:"as-well-as_block"},_vm._l((_vm.featuredCards),function(direction){return _c('div',{key:direction.code,staticClass:"study-direction as-well-as_st-dir",class:{
          math: direction.code === 'math',
          'ege-informatika': direction.code === 'ege-informatika',
        },on:{"click":function($event){return _vm.goToCourse(direction.url)}}},[_c('div',{staticClass:"direction-text"},[_c('p',{staticClass:"direction",domProps:{"innerHTML":_vm._s(direction.childrens_age)}}),_vm._v(" "),_c('h4',{domProps:{"innerHTML":_vm._s(direction.title)}}),_vm._v(" "),_c('p',{staticClass:"direction description"},[_vm._v(_vm._s(direction.description))]),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.goToCourse(direction.url)}}},[_vm._v("\n            "+_vm._s(_vm.detailData.data.textButton)+" >\n          ")])])])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }