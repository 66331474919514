//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalCloseButton from '@/components/v2/span/ModalCloseButton.vue'
export default {
  name: 'SummerPromoPopup',
  components: { ModalCloseButton },
  props: {
    text: {
      type: String,
      default: ` <b>6 смен</b><br> 3 июня — 14 июня<br> 17 июня — 28 июня<br> 1 июля — 12 июля<br> 15 июля — 26 июля<br> 29 июля — 9 августа<br> 12 августа — 23 августа<br><br> <b>Формат занятий</b><br> С 09:00 до 13:00 — половина дня<br> С 09:00 до 18:00 — полный день<br><br> <b>Что входит в стоимость</b><br> ⭐Мы стремительно развиваемся как ИИ и увлекаем ребят в Нейросети<br> ⭐Прогулки на свежем воздухе и активный отдых<br> ⭐3-х разовое питание<br> ⭐Разнообразная развлекательная программа в течение всего дня<br> ⭐Вести с полей — родительский чат<br><br> В завершающий день интенсива будет выпускной, где ребята презентуют свои проекты, покажут, чему научились и получат подарок от Алгоритмики! ✨
      `,
    },
    header: {
      type: String,
      default: 'Летние смены со скидкой на раннее бронирование',
    },
    isVis: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {
    closePopUp() {
      this.$emit('close')
    },
  },
}
