//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgramDetailsPopup from '@/components/v2/pop-up/ProgramDetails.vue'
import ScrollWrapperV2 from '@/components/v2/hoc/ScrollWrapperV2.vue'
import CtaButton from '@/components/v2/button/CtaButton.vue'
// import ArrowBase from '@/components/v2/span/ArrowBase.vue'
export default {
  name: 'ProgramSelector',
  components: { ScrollWrapperV2, ProgramDetailsPopup, CtaButton },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        routePath: 'index',
        isDetail: false,
        viewportWidth: 1440,
        data: {},
      }),
    },
  },
  data() {
    return {
      arrowButton: require('@/assets/img/v2/arrowButton.svg'),
      cardsBackground: {
        Lime: require('@/assets/img/v2/li/Lime.svg'),
        Blue: require('@/assets/img/v2/li/Blue.svg'),
        Purple: require('@/assets/img/v2/li/Purple.svg'),
        Sky: require('@/assets/img/v2/li/Sky.svg'),
        Pink: require('@/assets/img/v2/li/Pink.svg'),
        SpanishPink: require('@/assets/img/v2/li/SpanishPink.svg'),
        Yellow: require('@/assets/img/v2/li/Yellow.svg'),
        Green: require('@/assets/img/v2/li/Green.svg'),
        NadeshikoPink: require('@/assets/img/v2/li/NadeshikoPink.svg'),
        LightCyan: require('@/assets/img/v2/li/LightCyan.svg'),
      },
      showPopup: false,
      selectedIndex: null,
    }
  },
  watch: {},
  mounted() {},
  methods: {
    showPopUpEl(index) {
      this.selectedIndex = index
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
      this.selectedIndex = null
    },
    /** btn swipe */
    prevCard() {
      const wrapper = this.$refs.scrollWrapper.$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, -scrollAmount)
    },
    nextCard() {
      const wrapper = this.$refs.scrollWrapper.$refs.scrollWrapper
      const scrollAmount = wrapper.clientWidth
      this.smoothScroll(wrapper, scrollAmount)
    },
    smoothScroll(element, amount) {
      const start = element.scrollLeft
      const change = amount
      const duration = 300
      let currentTime = 0
      const increment = 20

      const animateScroll = () => {
        currentTime += increment
        const val = this.easeInOutQuad(currentTime, start, change, duration)
        element.scrollLeft = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }

      animateScroll()
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    },
  },
}
